import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-rows: auto;
  grid-row-gap: 30px;
  @media screen and (min-width: 778px) {
    grid-column-gap: 50px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 990px) {
    grid-column-gap: 30px;
    grid-template-columns: repeat(${(props) => (props.count > 4 ? 4 : props.count)}, 1fr);
  }
`;
