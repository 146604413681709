import styled from 'styled-components';
import Content from '../../../components/content';

export default styled(Content)`
  padding: 1rem;
  h2 {
    color: initial;
    font-size: 1.25rem;
    line-height: 1.9rem;
  }
`;
