import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useI18n } from '../../context/language';

import Wrapper from './wrapper';
import Page from './page';
import Content from './content';
import StyledLink from './link';

export const pageQuery = graphql`
  fragment HighlightedPages on wordpress__PAGE {
    custom {
      highlightedPages {
        ... on wordpress__wp_vacancies {
          id
          title
          excerpt
          link
          featured_media {
            localFile {
              name
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 300, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        ... on wordpress__PAGE {
          id
          title
          excerpt
          link
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 300, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const HighlightedPages = ({ custom }) => {
  const i18n = useI18n();

  if (!custom?.highlightedPages?.length > 0) {
    return null;
  }
  return (
    <Wrapper count={custom?.highlightedPages?.length}>
      {custom.highlightedPages.map((page) => (
        <Page key={page.id}>
          {page.featured_media?.localFile?.childImageSharp?.fluid && (
            <Link to={page.link}>
              <Img
                fluid={page.featured_media.localFile.childImageSharp.fluid}
                alt={page.featured_media.alt_text}
              />
            </Link>
          )}
          <Content>
            <Link to={page.link}><h2>{page.title}</h2></Link>
            <div dangerouslySetInnerHTML={{ __html: page.excerpt }} />
            <StyledLink to={page.link}>
              {i18n?.readMore || 'Read more'}
            </StyledLink>
          </Content>
        </Page>
      ))}
    </Wrapper>
  );
};

export default HighlightedPages;
