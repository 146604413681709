import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import HighlightedPages from 'gatsby-theme-wordpress/src/components/highlighted-pages';

import Layout from '../../layouts';
import Container from '../../components/container';
import Content from '../../components/content';

export const pageQuery = graphql`
  query HomePage($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      featured_media {
        caption
        localFile {
          name
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 540, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      title
      content

      acf {
        secondary_content
        quote_content
      }

      ...HighlightedPages
    }
  }
`;

const HomepageTemplate = ({ data }) => {
  const { page } = data;
  return (
    <Layout page={page}>
      <Container>
        <StyledBackgroundImage fluid={page.featured_media?.localFile?.childImageSharp?.fluid}>
          <Overlay>
            <OverlayInner>
              <article dangerouslySetInnerHTML={{ __html: page.featured_media?.caption }} />
            </OverlayInner>
            <Bars>
              <span />
              <span />
              <span />
            </Bars>
          </Overlay>
        </StyledBackgroundImage>

        <Wrapper>
          <Content>
            {page.title && (
              <h1>{page.title}</h1>
            )}
            {page.content && (
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            )}
          </Content>
        </Wrapper>

        {(page.acf.secondary_content || page.acf.quote_content) && (
          <Wrapper doubleColumn>
            {page.acf.secondary_content && (
              <div>
                <Content content={page.acf.secondary_content} />
              </div>
            )}
            {page.acf.quote_content && (
              <QuoteWrapper>
                <Quote open>
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" />
                  </svg>
                </Quote>
                <Content content={page.acf.quote_content} />
                <Quote close>
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z" />
                  </svg>
                </Quote>
              </QuoteWrapper>
            )}
          </Wrapper>
        )}

        {page.custom?.highlightedPages && (
          <Wrapper>
            <HighlightedPages {...page} />
          </Wrapper>
        )}
      </Container>
    </Layout>
  );
};

const StyledBackgroundImage = styled(BackgroundImage)`
  height: auto;
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-left: 0;
    margin-right: 0;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    min-height: 29.44rem;
  }
  && {
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      display: flex;
      align-items: flex-end;
    }
  }
`;

const Bars = styled.div`
  height: 12px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3px;
  span {
    height: 2px;
    display: block;
    background-color: ${(props) => props.theme.primaryColor};
    background-color: ${(props) => props.theme.primaryColor}E6; /* E6 = 90% alpha */
  }
`;

const OverlayInner = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor}E6; /* E6 = 90% alpha */
`;

const Overlay = styled.div`
  @keyframes fadein {
    from { opacity: 0; }
    to  { opacity: 1; }
  }
  animation: fadein 1.5s;
  padding-top: 6rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding-top: 0;
    margin-bottom: 2rem;
    max-width: 60%;
  }
  article {
    font-size: 1.556rem;
    line-height: 2rem;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    padding: 2rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      font-size: 2rem;
      line-height: 2.722rem;
    }
  }
`;

const Quote = styled.div`
  position: absolute;

  ${(props) => props.open && css`
    top: -0.9rem;
    left: 1rem;
  `}
  ${(props) => props.close && css`
    bottom: -0.9rem;
    right: 1rem;
  `}
  svg {
    height: 1.8rem;
    color: ${(props) => props.theme.primaryColor};
  }
`;

const QuoteWrapper = styled.div`
  position: relative;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 1rem;
  ${Content} {
    p {
      font-size:  1.333rem;
      line-height: 2.278rem;
      font-style: italic;
      margin-bottom: 0;
      a {
        font-size: 1rem;
        padding-right: .6rem;
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: ${(props) => props.theme.sectionSpace} 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.gray1};
  }
  ${(props) => props.doubleColumn && css`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: ${props.theme.grid.columnGap};
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export default HomepageTemplate;
